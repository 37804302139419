import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Product } from 'src/app/modals/product.model';
import { CartItem } from 'src/app/modals/cart-item';
import { CartService } from '../services/cart.service';
import { SidebarMenuService } from '../sidebar/sidebar-menu.service';
import { AppSettings, Settings } from '../services/color-option.service';
import { FormControl, FormGroup,ReactiveFormsModule } from '@angular/forms';
import { NavigationExtras, Router } from '@angular/router';
import { oUser } from 'src/app/modals/user';
import { environment } from 'src/environments/environment';
import { AccountService } from '../services/account.service';
import { PublicService } from '../services/public.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})
export class HeaderComponent implements OnInit {
  
  @Input() usuario :oUser;
  @Input() menu :any[];
  public sidenavMenuItems:Array<any>;

  public currencies = ['USD', 'EUR'];
  public currency:any;
  public flags = [
    { name:'English', image: 'assets/images/flags/gb.svg' },
    { name:'German', image: 'assets/images/flags/de.svg' },
    { name:'French', image: 'assets/images/flags/fr.svg' },
    { name:'Russian', image: 'assets/images/flags/ru.svg' },
    { name:'Turkish', image: 'assets/images/flags/tr.svg' }
  ]
  public flag:any;

  products: Product[];

  indexProduct: number;
  shoppingCartItems: CartItem[] = [];
  public settings: Settings;

  public URL_FILES:string = environment.filesUrl;
  public mainData:any;
  constructor(private cartService: CartService, 
              public appSettings:AppSettings,
              public router: Router,
              public account: AccountService,
              public pubService: PublicService) {
    this.settings = this.appSettings.settings;
    this.cartService.getItems().subscribe(shoppingCartItems => this.shoppingCartItems = shoppingCartItems);
    this.pubService.getMainData()
    .subscribe( (data) => {
      this.mainData = data;
    }
    );
    
  }

  ngOnInit() {
    this.currency = this.currencies[0];
    this.flag = this.flags[0];

  }

  public changeCurrency(currency){
    this.currency = currency;
  }
  public changeLang(flag){
    this.flag = flag;
  }

  public buscar(buscar){

    this.router.navigate(["home/products/0/left-sidebar"], {queryParams: { string: buscar } });

  }



}
