import { Component, OnInit } from '@angular/core';
import { MenuService } from '../services/menu.service';
import { ActivatedRoute } from '@angular/router';
import { PublicService } from '../services/public.service';
import * as internal from 'assert';

interface oMenu {
  catDescripcion?: any;
  submenu?: any[][];
}

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.sass']
})



export class MenuComponent implements OnInit {

  menu:any[];
  menufinal:oMenu[]=[];
  constructor(public menuService: MenuService,
              public pubServices: PublicService
    ) { }

  ngOnInit() {
   

    this.pubServices.getMenuData().subscribe((res) => {

        if(res["checkboxMenu"]==0){
           this.menuService.getmenu().subscribe((subres) => {
            let menu_final:[]=[];
            this.transformarrMenu(res["menu"],menu_final,0);
            this.dibujaMenu(menu_final);
          });

        }else{
          this.menuService.getmenu().subscribe((subres) => {
            this.dibujaMenu(subres.payload);
          });
        }

    });

/*

    this.menuService.getmenu().subscribe((res) => {

        let qttyXColum = 10;
        this.menu =  Array.from(res.payload);
        for (var imenu of this.menu) {
          let submenuoriginal:any[]=Array.from(imenu.submenu);
          submenuoriginal.sort(function(a,b){
            return a.scaDescripcion.localeCompare(b.scaDescripcion);
          })

          let mitad = Math.ceil(submenuoriginal.length / qttyXColum);        
          if (mitad>0){
            let inicio = 0;
            for (let i = 1; i < mitad + 1; i++) {              
              var primeraParte = submenuoriginal.slice(inicio, inicio + qttyXColum);
              let currentOmenu:oMenu = {"catDescripcion":(i==1)?imenu.catDescripcion:"" ,
                                        "submenu":[]};
              currentOmenu.submenu.push( primeraParte) ;   
              inicio = inicio + qttyXColum;
              this.menufinal.push(currentOmenu); 
            }
              
          }else{
              let currentOmenu:oMenu = {"catDescripcion":imenu.catDescripcion , 
                                        "submenu":[]};
              currentOmenu.submenu.push( submenuoriginal) ;                      


              this.menufinal.push(currentOmenu);                       
          }
        }
 

    })
    */
  }

  transformarrMenu( _arr:any[] = [], _arr_ret:any[], nivel:number){
    for (let i = 0; i < _arr.length; i++) {
      if (nivel==0){
           _arr_ret.push({catCategoriaId: 4,
                          catCod: _arr[i].data.catCod,
                          catDescripcion: _arr[i].data.descripcion,
                          catServicio: 1,
                          catWeb: 1,
                          submenu:[]});   
      }
      if (nivel==1){
          console.error(JSON.stringify(_arr[i].data.relaciones));
          _arr_ret.push({scaSubCategoriaId: 4,
                         scaDescripcion: _arr[i].data.descripcion});   
      }  
      if (_arr[i].children?.length > 0 ){
        this.transformarrMenu(_arr[i].children, _arr_ret[_arr_ret.length - 1].submenu, (nivel + 1));
      }    
    }
  }

  dibujaMenu(res){
    let qttyXColum = 10;
    this.menu =  Array.from(res);
    for (var imenu of this.menu) {
      let submenuoriginal:any[]=Array.from(imenu.submenu);
      submenuoriginal.sort(function(a,b){
        return a.scaDescripcion.localeCompare(b.scaDescripcion);
      })

      let mitad = Math.ceil(submenuoriginal.length / qttyXColum);        
      if (mitad>0){
        let inicio = 0;
        for (let i = 1; i < mitad + 1; i++) {              
          var primeraParte = submenuoriginal.slice(inicio, inicio + qttyXColum);
          let currentOmenu:oMenu = {"catDescripcion":(i==1)?imenu.catDescripcion:"" ,
                                    "submenu":[]};
          currentOmenu.submenu.push( primeraParte) ;   
          inicio = inicio + qttyXColum;
          this.menufinal.push(currentOmenu); 
        }
          
      }else{
          let currentOmenu:oMenu = {"catDescripcion":imenu.catDescripcion , 
                                    "submenu":[]};
          currentOmenu.submenu.push( submenuoriginal) ;                      


          this.menufinal.push(currentOmenu);                       
      }
    }
  }



  openMegaMenu(){
    let pane = document.getElementsByClassName('cdk-overlay-pane');
    [].forEach.call(pane, function (el) {
        if(el.children.length > 0){
          if(el.children[0].classList.contains('mega-menu')){
            el.classList.add('mega-menu-pane');
          }
        }
    });
  }


}
